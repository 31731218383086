<!-- 关联其他 -->

<template>
  <div class="related-others-dialog">
    <w-dialog ref="dialogRef" title="关联其他" width="60%" top="5vh" :hideFooter="true">
      <el-form class="area-form" :model="currentRow" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="name" label="酒店名称">
              <el-input v-model="currentRow.h_name" readonly></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="name" label="联系方式">
              <el-input v-model="currentRow.h_hotline" readonly></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="name" label="酒店地址">
              <el-input v-model="currentRow.h_address" readonly></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="name" label="酒店简介">
              <el-input v-model="currentRow.h_introduce" readonly :rows="4" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="景点列表" name="2"></el-tab-pane>
        <el-tab-pane label="餐厅列表" name="4"></el-tab-pane>
        <el-tab-pane label="门店列表" name="5"></el-tab-pane>
      </el-tabs>

      <common-table ref="refTable" tableHeight="350" :apiName="BasicApi.getSourceAssocList" :columns="tableColumns"
        :extraParame="extraParame" @statusChange="statusChange">
      </common-table>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, nextTick, } from "vue";
  import { BasicApi, } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";

  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 弹框对象
      const currentRow = ref('');  // 当前行数据
      const activeName = ref('2');  // 标签页下标
      const refTable = ref(null);  // 表格对象
      /** 表格配置数据 */
      const tableColumns = ref([
        {
          prop: "name",
          label: "景点名称",
        },
        {
          type: "switch",
          prop: "assoc_status",
          label: "关联状态",
          token: "r_x9WbzLehsU8hJCUJJfPLnAxVrtDB",
        },
        {
          prop: "assoc_time",
          label: "最新操作时间",
        },
        {
          prop: "assoc_user",
          label: "最新操作人",
        },
      ]);
      const main_type = ref('');  // 资源类型
      const extraParame = ref({  // 自定义参数
        main_type: '',  // 资源类型：1 景区 2 景点 3 酒店 4 餐厅 5 门店
        main_id: '',  // 对应资源id
        assoc_type: '',  // 附属资源类型：1 景区 2 景点 3 酒店 4 餐厅 5 门店
      });
      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = (row, type) => {
        currentRow.value = row;
        activeName.value = '2';
        extraParame.value.main_type = type;
        extraParame.value.assoc_type = activeName.value;
        if (type == '3') {
          // 酒店
          extraParame.value.main_id = row.h_id;
        }
        dialogRef.value.show();
        nextTick(() => {
          refTable.value.tableLoad();
        });
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 打开对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 切换标签页
       * 
       * **/
      const handleClick = () => {
        extraParame.value.assoc_type = activeName.value;
        refTable.value.tableLoad();
      }
      /**
       * 
       * 修改关联状态
       * 
       * **/
      const statusChange = (row) => {
        let params = {
          main_type: extraParame.value.main_type,  // 资源类型：1 景区 2 景点 3 酒店 4 餐厅 5 门店
          main_id: extraParame.value.main_id,  // 对应资源id
          assoc_type: extraParame.value.assoc_type,  // 附属资源类型：1 景区 2 景点 3 酒店 4 餐厅 5 门店
          assoc_id: row.id,  // 对应附属资源id
        };
        BasicApi.resourceAssoc(params).then((res) => {
          if (res.Code === 200) {
            ElMessage.success('操作成功！');
            refTable.value.tableLoad();
            emit('submit');
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      return {
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        currentRow,
        activeName,
        refTable,
        tableColumns,
        statusChange,
        BasicApi,
        handleClick,
        main_type,
        extraParame,
      };
    },
  };
</script>

<style lang="scss">
  .related-others-dialog {
    .el-tabs__item {
      height: 50px;
      line-height: 50px;
      color: var(--text-second-color);

      &.is-active {
        font-weight: 700;
        font-size: 16px;
        color: var(--theme-color);
      }
    }
  }
</style>