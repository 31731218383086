<template>
  <div class="resmanage main-cnt">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        label="景区列表"
        name="scenic"
        v-if="authData.indexOf(scenicToken) != -1"
      >
        <common-table
          ref="scenicTable"
          tableHeight="calc(100vh - 325px)"
          :ischeck="false"
          :ispaging="true"
          :apiName="BasicApi.scenicList"
          :filters="filters1"
          :columns="tableColumns1"
          @edit="scenicEdit"
          @areaClick="areaClick"
          @spotClick="spotClick"
          @statusChange="scenicSpotStatus"
        >
          <template #operate>
            <el-button
              type="primary"
              round
              @click="showAreaDialog"
              v-if="authData.indexOf('r_AvDaI0zi1Hj7RCTrMXcPUphQtF3Y') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增景区
            </el-button>
            <el-button
              class="black-bg-btn"
              round
              @click="showSpotDialog"
              v-if="authData.indexOf('r_F82pAxvIUuXmKO1Hwsd6n0RMf9rZ') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增景点
            </el-button>
          </template>

          <template #points_rule="{ row }">
            <div>
              <el-select v-model="row.points_rule" placeholder="请选择积分规则" @change="integralRuleChange(row, '1')">
                <el-option :label="item.name" :value="item.id" v-for="item in integralRuleOption" :key="item.id"></el-option>
              </el-select>
            </div>
          </template>
        </common-table>
      </el-tab-pane>
      <el-tab-pane
        label="酒店列表"
        name="hotel"
        v-if="authData.indexOf(hotelToken) != -1"
      >
        <common-table
          ref="hotelTable"
          tableHeight="calc(100vh - 325px)"
          :ischeck="false"
          :ispaging="true"
          :apiName="BasicApi.hotelList"
          :filters="filters2"
          :columns="tableColumns2"
          @edit="hotelEdit"
          @statusChange="hotelStatus"
          @relatedOthers="relatedOthers"
        >
          <template #operate>
            <el-button
              type="primary"
              round
              @click="showHotelDialog"
              v-if="authData.indexOf('r_5be3UfFdAp4V9gQSEZYDswPrcmX0') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增酒店
            </el-button>
          </template>

          <template #points_rule="{ row }">
            <div>
              <el-select v-model="row.points_rule" placeholder="请选择积分规则" @change="integralRuleChange(row, '2')">
                <el-option :label="item.name" :value="item.id" v-for="item in integralRuleOption" :key="item.id"></el-option>
              </el-select>
            </div>
          </template>
        </common-table>
      </el-tab-pane>
      <el-tab-pane
        label="餐厅列表"
        name="dining"
        v-if="authData.indexOf(diningToken) != -1"
      >
        <common-table
          ref="diningTable"
          tableHeight="calc(100vh - 325px)"
          :ischeck="false"
          :ispaging="true"
          :apiName="BasicApi.dinnerList"
          :filters="filters3"
          :columns="tableColumns3"
          @edit="diningEdit"
          @statusChange="dinnerStatus"
        >
          <template #operate>
            <el-button
              type="primary"
              round
              @click="showDiningDialog"
              v-if="authData.indexOf('r_nmGXdOaioYrfNRLj7A5lzy6I3HbB') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增餐厅
            </el-button>
          </template>

          <template #points_rule="{ row }">
            <div>
              <el-select v-model="row.points_rule" placeholder="请选择积分规则" @change="integralRuleChange(row, '3')">
                <el-option :label="item.name" :value="item.id" v-for="item in integralRuleOption" :key="item.id"></el-option>
              </el-select>
            </div>
          </template>
        </common-table>
      </el-tab-pane>
      <el-tab-pane
        label="门店列表"
        name="store"
        v-if="authData.indexOf(polymerizationToken) != -1"
      >
      <common-table ref="storeTable" tableHeight="calc(100vh - 325px)" :filters="filters4"
        :ispaging="true" :apiName="BasicApi.getPolymerizationList" :columns="tableColumns4" @onAddEditor="onAddEditor" @statusChange="storeStatus">
        <template #operate>
          <el-button type="primary" round @click="onAddEditor"
            v-if="authData.indexOf('r_7FX3mQVB8dZAednNeaB5Y3Acaj3e') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增门店
          </el-button>
        </template>

        <template #points_rule="{ row }">
          <el-select v-model="row.points_rule" placeholder="请选择积分规则" @change="integralRuleChange(row, '4')">
            <el-option :label="item.name" :value="item.id" v-for="item in integralRuleOption" :key="item.id"></el-option>
          </el-select>
        </template>

        <template #pom_status="{ row }">
          <el-switch v-model="row.pom_status"
            inline-prompt
            active-text="开"
            inactive-text="关"
            :width="45"
            :active-value="1"
            :inactive-value="2"
            :disabled="true"
          />
        </template>

        <template #refund_status="{ row }" >
          <el-switch
            v-model="row.pom_refund_status"
            inline-prompt
            active-text="开"
            inactive-text="关"
            :width="45"
            :active-value="2"
            :inactive-value="1"
            :disabled="true"
          />
        </template>
      </common-table>
      </el-tab-pane>
    </el-tabs>

    <!-- 新增/编辑 景区弹框 -->
    <w-dialog
      ref="scenicArea"
      class="scenic-area"
      :title="isAreaEdit ? '编辑景区' : '新增景区'"
      width="55%"
      btnWidth="140px"
      top="8vh"
      :confirmText="isAreaEdit ? '确认编辑' : '确认新增'"
      @wConfirm="scenicEditConfirm"
    >
      <el-form
        class="area-form"
        ref="areaFormObj"
        :model="areaForm"
        :rules="areaRules"
        labelPosition="top"
      >
        <el-form-item label="选择项目" prop="pname">
          <el-select
            v-model="areaForm.pname"
            clearable
            :disabled="isAreaEdit"
            placeholder="请选择项目"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="景区名称" prop="name"
          ><el-input
            v-model="areaForm.name"
            placeholder="请输入景区名称"
          ></el-input
        ></el-form-item>
        <el-divider></el-divider>
        <el-form-item label="通行时间" prop="transitTime">
          <el-time-picker
            v-model="areaForm.transitTime"
            is-range
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="景区地址" prop="addr"
          ><el-input
            v-model="areaForm.addr"
            placeholder="请输入景区地址"
          ></el-input
        ></el-form-item>
        <el-form-item label="景区坐标" prop="coord"
          ><el-input
            v-model="areaForm.coord"
            placeholder="请输入景区坐标"
          ></el-input
        ></el-form-item>
        <el-form-item label="特色标签1" prop="tag1"
          ><el-input
            v-model="areaForm.tag1"
            placeholder="请输入特色标签1"
          ></el-input
        ></el-form-item>
        <el-form-item label="特色标签2" prop="tag2"
          ><el-input
            v-model="areaForm.tag2"
            placeholder="请输入特色标签2"
          ></el-input
        ></el-form-item>
        <el-form-item class="block" label="景区简介" prop="intro"
          ><div ref="scenicEditorElem"></div
        ></el-form-item>
      </el-form>
    </w-dialog>

    <!-- 新增/编辑 景点弹框 -->
    <w-dialog
      ref="scenicSpot"
      class="scenic-spot"
      :title="isSpotEdit ? '编辑景点' : '新增景点'"
      width="55%"
      btnWidth="140px"
      top="15vh"
      :confirmText="isSpotEdit ? '确认编辑' : '确认新增'"
      @wConfirm="spotEditConfirm"
    >
      <el-form
        class="spot-form"
        ref="spotFormObj"
        :model="spotForm"
        :rules="spotRules"
        labelPosition="top"
      >
        <el-form-item v-if="!isSpotEdit" label="选择项目" prop="village">
          <el-select
            v-model="spotForm.village"
            clearable
            placeholder="请选择项目"
            @change="projectChange"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!isSpotEdit" label="选择景区" prop="scenic">
          <el-select
            v-model="spotForm.scenic"
            clearable
            placeholder="请选择景区"
          >
            <el-option
              :label="item.s_name"
              :value="item.s_id"
              v-for="item in scenicOptions"
              :key="item.s_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider v-if="!isSpotEdit"></el-divider>
        <el-form-item label="景点名称" prop="name"
          ><el-input
            v-model="spotForm.name"
            placeholder="请输入景点名称"
          ></el-input
        ></el-form-item>
        <el-form-item label="通行时间" prop="transitTime">
          <el-time-picker
            v-model="spotForm.transitTime"
            is-range
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-time-picker>
        </el-form-item>
        <el-form-item class="block" label="景点简介" prop="introduce"
          ><div ref="spotEditorElem"></div
        ></el-form-item>
      </el-form>
    </w-dialog>

    <!-- 景区入口 弹框 -->
    <w-dialog
      ref="areaEntrance"
      class="entrance"
      title="入口列表"
      width="60%"
      btnWidth="140px"
      top="10vh"
      :hideFooter="true"
    >
      <div class="name">景区名称</div>
      <common-table
        ref="areaTable"
        tableHeight="calc(100vh - 360px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="BasicApi.entranceList"
        :columns="areaTableColumns"
        @statusChange="statusChange"
      ></common-table>
    </w-dialog>

    <!-- 景点入口 弹框 -->
    <w-dialog
      ref="spotEntrance"
      class="entrance"
      title="入口列表"
      width="60%"
      btnWidth="140px"
      top="10vh"
      :hideFooter="true"
    >
      <div class="name">景点名称</div>
      <common-table
        ref="spotTable"
        tableHeight="calc(100vh - 360px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="BasicApi.spotList"
        :extraParame="{ s_id: currentRow.s_id }"
        :columns="spotTableColumns"
        @statusChange="spotStatusChange"
        @edit="spotEdit"
      ></common-table>
    </w-dialog>

    <!-- 新增/编辑 酒店弹框 -->
    <w-dialog
      ref="hotelDialog"
      class="scenic-area"
      :title="isHotelEdit ? '编辑酒店' : '新增酒店'"
      width="55%"
      btnWidth="140px"
      top="15vh"
      :confirmText="isHotelEdit ? '确认编辑' : '确认新增'"
      @wConfirm="hotelConfirm"
    >
      <el-form
        class="area-form"
        ref="hotelFormObj"
        :model="hotelForm"
        :rules="hotelRules"
        labelPosition="top"
      >
        <el-form-item label="选择项目" prop="village">
          <el-select
            v-model="hotelForm.village"
            clearable
            :disabled="isHotelEdit"
            placeholder="请选择项目"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="酒店名称" prop="name"
          ><el-input
            v-model="hotelForm.name"
            placeholder="请输入酒店名称"
          ></el-input
        ></el-form-item>
        <el-form-item label="联系电话" prop="tel"
          ><el-input
            v-model="hotelForm.tel"
            placeholder="请输入酒店联系电话"
          ></el-input
        ></el-form-item>
        <el-form-item label="酒店地址" prop="addr"
          ><el-input
            v-model="hotelForm.addr"
            placeholder="请输入酒店地址"
          ></el-input
        ></el-form-item>
        <el-form-item class="block" label="酒店简介" prop="intro">
          <el-input
            v-model="hotelForm.intro"
            :rows="7"
            type="textarea"
            placeholder="请输入关于酒店的详细描述"
          />
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 新增/编辑 餐厅弹框 -->
    <w-dialog
      ref="diningDialog"
      class="scenic-area"
      :title="isDiningEdit ? '编辑餐厅' : '新增餐厅'"
      width="55%"
      btnWidth="140px"
      top="15vh"
      :confirmText="isDiningEdit ? '确认编辑' : '确认新增'"
      @wConfirm="dinnerConfirm"
    >
      <el-form
        class="area-form"
        ref="diningFormObj"
        :model="diningForm"
        :rules="diningRules"
        labelPosition="top"
      >
        <el-form-item label="选择项目" prop="village">
          <el-select
            v-model="diningForm.village"
            clearable
            :disabled="isDiningEdit"
            placeholder="请选择项目"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="餐厅名称" prop="name"
          ><el-input
            v-model="diningForm.name"
            placeholder="请输入酒店名称"
          ></el-input
        ></el-form-item>
        <el-form-item label="联系电话" prop="tel"
          ><el-input
            v-model="diningForm.tel"
            placeholder="请输入餐厅联系电话"
          ></el-input
        ></el-form-item>
        <el-form-item label="餐厅地址" prop="addr"
          ><el-input
            v-model="diningForm.addr"
            placeholder="请输入餐厅地址"
          ></el-input
        ></el-form-item>
        <el-form-item label="餐厅坐标" prop="coordinate"
          ><el-input
            v-model="diningForm.coordinate"
            placeholder="请输入餐厅坐标"
          ></el-input
        ></el-form-item>
        <el-form-item label="餐厅标签" prop="tag"
          ><el-input
            v-model="diningForm.tag"
            placeholder="请输入餐厅标签"
          ></el-input
        ></el-form-item>
        <el-form-item label="营业时间" prop="transitTime">
          <el-time-picker
            v-model="diningForm.transitTime"
            is-range
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-time-picker>
        </el-form-item>
        <el-form-item class="block" label="餐厅简介" prop="intro">
          <el-input
            v-model="diningForm.intro"
            :rows="7"
            type="textarea"
            placeholder="请输入餐厅简介"
          />
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 新增、编辑门店对话框 -->
    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.pom_id ? '编辑门店' : '新增门店'" width="50%" btnWidth="140px"
      top="20vh" :confirmText="ruleForm.pom_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="storeRules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="选择项目" prop="pom_pid">
              <el-select v-model="ruleForm.pom_pid" clearable placeholder="请选择项目">
                <el-option :label="item.p_name" :value="item.p_id" v-for="item in projectOptions" :key="item.p_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="选择门店所属分类" prop="pom_type">
              <el-select v-model="ruleForm.pom_type" clearable placeholder="请选择门店所属分类">
                <el-option :label="item.t_Name" :value="item.pom_type" v-for="item in classification"
                  :key="item.pom_type">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="门店名称" prop="pom_name">
              <el-input v-model.trim="ruleForm.pom_name" clearable placeholder="请输入门店名称" maxlength="10"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="pom_remark" label="门店备注">
              <el-input type="textarea" rows="3" placeholder="请输入详细门店备注" v-model.trim="ruleForm.pom_remark"
                maxlength="30">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <!-- 关联其他 -->
    <RelatedOthers ref="correlatRef" @submit="onSubmit"></RelatedOthers>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, watch, nextTick, computed } from "vue";
import { ElMessage } from "element-plus";
import { BasicApi, ScenicApi } from "@/plugins/api.js";
import * as dayjs from "dayjs";
// 引入富文本编辑器
import Editor from "wangeditor";
import * as Qiniu from "qiniu-js";
import { useStore } from "vuex";
import RelatedOthers from "../components/RelatedOthers.vue";

const scenicToken = ref("r_TZtls3k1wYa2JgehxorV5jS8Rbi6");
const hotelToken = ref("r_HALnIlC6aevhdfrEspQDX0BoWJ9Z");
const diningToken = ref("r_XQRGSWZFhxNUalDuEvcYdHOoBzCs");
const polymerizationToken = ref("r_C5c4yYswxkegWtfrmP0IIdSvGA9Z");

const store = useStore();
const qiniuData = computed(() => store.state.Config.qiniuData);
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
function handleClick({ props }) {
  switch (props.name) {
    case "scenic":
      nextTick(() => {
        scenicTable.value.tableLoad();
      });

      break;
    case "hotel":
      nextTick(() => {
        hotelTable.value.tableLoad();
      });

      break;
    case "dining":
      nextTick(() => {
        diningTable.value.tableLoad();
      });

      break;
    case "store":
      nextTick(() => {
        storeTable.value.tableLoad();
      });

      break;
  }
}
const activeName = ref("");

watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
      if (data.indexOf(scenicToken.value) != -1) {
        activeName.value = "scenic";
        nextTick(() => {
          scenicTable.value.tableLoad();
        });
      } else if (
        data.indexOf(scenicToken.value) == -1 &&
        data.indexOf(hotelToken.value) != -1
      ) {
        activeName.value = "hotel";
        nextTick(() => {
          hotelTable.value.tableLoad();
        });
      } else if (
        data.indexOf(scenicToken.value) == -1 &&
        data.indexOf(hotelToken.value) == -1 &&
        data.indexOf(diningToken.value) != -1
      ) {
        activeName.value = "dining";
        nextTick(() => {
          diningTable.value.tableLoad();
        });
      } else if (
        data.indexOf(scenicToken.value) == -1 &&
        data.indexOf(hotelToken.value) == -1 &&
        data.indexOf(diningToken.value) == -1 &&
        data.indexOf(polymerizationToken.value) != -1
      ) {
        activeName.value = "store";
        nextTick(() => {
          storeTable.value.tableLoad();
        });
      }
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 景区 表格1部分 开始 */
/** 表格1 筛选条件列表 */
const filters1 = ref([
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
  },
]);
/** 表格1 配置数据 */
const tableColumns1 = ref([
  {
    prop: "s_id",
    label: "景区ID",
    minWidth: 60,
    color: "--text-color",
  },
  {
    prop: "p_name",
    label: "所属项目",
    color: "--text-third-color",
  },
  {
    prop: "s_name",
    label: "景区名称",
    minWidth: 120,
    color: "--text-color",
    showTooltip: true,
  },
  {
    type: "block",
    prop: "entrance_count",
    label: "景区入口",
    minWidth: 60,
    active: "areaClick",
    token: scenicToken.value,
  },
  {
    type: "block",
    prop: "spot_count",
    label: "包含景点",
    minWidth: 60,
    active: "spotClick",
    token: scenicToken.value,
  },
  {
    type: "customRender",
    prop: "points_rule",
    label: "积分规则",
    minWidth: 130,
  },
  {
    prop: "s_place",
    label: "地址信息",
    minWidth: 150,
    color: "--text-color",
    showTooltip: true,
  },
  {
    type: "switch",
    prop: "points_ded",
    label: "抵扣积分",
    token: "r_j8kMlM7MNlJWzKpv42UZYOlm6s4Y",
  },
  {
    prop: "s_introduce",
    label: "景区简介",
    minWidth: 150,
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "r_r03OMVyT1Bn6FXw4cbkEphYfIxvG",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 景区表格对象 */
const scenicTable = ref(null);
/** 当前操作的行数据 */
const currentRow = ref(null);
/** 景区入口点击处理 */
const areaClick = (row) => {
  currentRow.value = row;
  areaEntrance.value.show();
  nextTick(() => {
    // 获取景区入口列表数据
    areaTable.value.tableLoad();
  });
};
/** 景点入口点击处理 */
const spotClick = (row) => {
  currentRow.value = row;
  spotEntrance.value.show();
  nextTick(() => {
    // 获取包含景点列表数据
    spotTable.value.tableLoad();
  });
};
/** 表格1 编辑 */
const scenicEdit = (row) => {
  isAreaEdit.value = true;
  scenicArea.value.show();
  ScenicApi.semanageInfo({ s_id: row.s_id }).then((res) => {
    if (res.Code === 200) {
      currentRow.value = res.Data;
      let times = res.Data.s_business_hours.split("-");
      let st = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[0]);
      let et = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[1]);
      areaForm.pname = res.Data.p_name;
      areaForm.name = res.Data.s_name;
      areaForm.transitTime = [st, et];
      areaForm.addr = res.Data.s_place;
      areaForm.coord = res.Data.s_coordinate;
      areaForm.tag1 = res.Data.s_tag1;
      areaForm.tag2 = res.Data.s_tag2;
      areaForm.intro = res.Data.s_introduce;
      areaForm.images = res.Data.images || [];
      store.dispatch("getQiniuData");
      scenicEditorInit();
    } else {
      let msg = res.Message ? res.Message : "获取景区详情数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 新增景区 */
const showAreaDialog = () => {
  isAreaEdit.value = false;
  areaForm.pname = "";
  areaForm.name = "";
  areaForm.transitTime = [];
  areaForm.addr = "";
  areaForm.coord = "";
  areaForm.tag1 = "";
  areaForm.tag2 = "";
  areaForm.intro = "";
  areaForm.images = [];

  scenicArea.value.show();
  nextTick(() => {
    store.dispatch("getQiniuData");
    scenicEditorInit();
  });
};

/** 新增景点 */
const showSpotDialog = () => {
  isSpotEdit.value = false;
  spotForm.village = ""; // 项目
  spotForm.scenic = ""; // 景区
  spotForm.name = ""; // 景点名称
  spotForm.transitTime = []; // 通行时间
  spotForm.introduce = ""; // 景点简介
  spotForm.images = [];
  scenicSpot.value.show();
  nextTick(() => {
    store.dispatch("getQiniuData");
    spotEditorInit();
  });
};

/** 新增/编辑 景区弹框 */
const scenicArea = ref(null);
/** 景区编辑/新增操作 */
const isAreaEdit = ref(false);
/** 表单对象 */
const areaFormObj = ref(null);
/** 表单数据对象 */
const areaForm = reactive({
  pname: "", // 项目
  name: "", // 景区名称
  transitTime: "", // 通行时间
  addr: "", // 景区地址
  coord: "", // 景区坐标
  tag1: "", // 特色标签1
  tag2: "", // 特色标签2
  intro: "", // 景区简介
  images: [], //图片
});
/** 表单规则对象 */
const areaRules = reactive({
  pname: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入景区名称",
      trigger: "blur",
    },
  ],
  transitTime: [
    {
      required: true,
      message: "请选择通行时间",
      trigger: "blur",
    },
  ],
  addr: [
    {
      required: true,
      message: "请输入景区地址",
      trigger: "blur",
    },
  ],
  coord: [
    {
      required: true,
      message: "请输入景区坐标",
      trigger: "blur",
    },
  ],
  tag1: [
    {
      required: true,
      message: "请输入特色标签1",
      trigger: "blur",
    },
  ],
  tag2: [
    {
      required: true,
      message: "请输入特色标签2",
      trigger: "blur",
    },
  ],
  intro: [
    {
      required: true,
      message: "请输入景区简介",
      trigger: "change",
    },
  ],
});
/** 景区 富文本容器 */
const scenicEditorElem = ref(null);
/** 景区 富文本实例对象 */
const scenicEditor = ref(null);
/** 景区 富文本初始化 */
const scenicEditorInit = () => {
  scenicEditor.value = new Editor(scenicEditorElem.value);
  scenicEditor.value.config.colors = [
    "#000000",
    "#eeece0",
    "#1c487f",
    "#4d80bf",
    "#0072f6",
    "#1a1a1a",
    "#FFB900",
    "#EB5774",
    "#f2f2f2",
    "#666666",
    "#999999",
    "#cccccc",
  ];
  // 配置 onchange 回调函数
  scenicEditor.value.config.onchange = (newHtml) => {
    areaForm.intro = newHtml;
    areaFormObj.value.validateField("intro");
  };
  // 自定义菜单配置
  scenicEditor.value.config.menus = [
    "head",
    "bold",
    "fontSize",
    "fontName",
    "italic",
    "underline",
    "strikeThrough",
    "indent",
    "lineHeight",
    "foreColor",
    "justify",
    "quote",
    "splitLine",
    "image",
    "undo",
    "redo",
  ];
  // 限制上传图片格式
  scenicEditor.value.config.uploadImgAccept = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
  ];
  scenicEditor.value.config.qiniu = true;
  scenicEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
  const upToken = qiniuData.value.token;
  const domain = qiniuData.value.http_domain;
  scenicEditor.value.config.customUploadImg = (file) => {
    if (file.length) {
      file.forEach((el) => {
        const myDate = new Date();
        const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
        const timeData = Date.parse(new Date()); //当前时间时时间戳
        const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
        const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
        const filename = el.name;
        const index = filename.lastIndexOf(".");
        const suffix = filename.substr(index + 1);
        const key = `resource-scenic-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
        const config = {
          // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          cdnUphost: domain,
        };
        const putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        const observable = Qiniu.upload(el, key, upToken, putExtra, config);
        observable.subscribe({
          error: () => {},
          complete: (res) => {
            const sourceLink = domain + res.key;
            scenicEditor.value.cmd.do(
              "insertHtml",
              '<img src="' + sourceLink + '" style="max-width:100%;"/>'
            );
            areaForm.images.push({
              filename: filename,
              qiniu_key: res.key,
              size: el.size,
            });
          },
        });
      });
    }
  };

  scenicEditor.value.config.height = 300;
  // 创建富文本实例
  scenicEditor.value.create();
  scenicEditor.value.txt.html(areaForm.intro);
};
/** 景区新增/编辑 确认 */
const scenicEditConfirm = () => {
  areaFormObj.value.validate((valid) => {
    if (valid) {
      scenicArea.value.isLoading = true;
      let stime = dayjs(areaForm.transitTime[0]).format("HH:mm:ss");
      let etime = dayjs(areaForm.transitTime[1]).format("HH:mm:ss");
      let data = {
        s_name: areaForm.name,
        s_business_hours: stime + "-" + etime,
        s_place: areaForm.addr,
        s_coordinate: areaForm.coord,
        s_tag1: areaForm.tag1,
        s_tag2: areaForm.tag2,
        s_introduce: areaForm.intro,
        images: areaForm.images,
      };
      let url = "scenicAdd";
      if (isAreaEdit.value) {
        url = "scenicUpdate";
        data.s_id = currentRow.value.s_id;
      } else {
        data.s_pid = areaForm.pname;
      }
      BasicApi[url](data).then((res) => {
        scenicArea.value.isLoading = false;
        let text = isAreaEdit.value ? "编辑" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`景区${text}成功！`);
          scenicArea.value.close();
          // 重新获取景区数据
          scenicTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `景区${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 新增/编辑 景点弹框 */
const scenicSpot = ref(null);
/** 景点编辑/新增操作 */
const isSpotEdit = ref(false);
/** 表单对象 */
const spotFormObj = ref(null);
/** 表单数据对象 */
const spotForm = reactive({
  village: "", // 项目
  scenic: "", // 景区
  name: "", // 景点名称
  transitTime: [], // 通行时间
  introduce: "", // 景点简介
});
/** 表单规则对象 */
const spotRules = reactive({
  village: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  scenic: [
    {
      required: true,
      message: "请选择景区",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入景点名称",
      trigger: "blur",
    },
  ],
  transitTime: [
    {
      required: true,
      message: "请选择通行时间",
      trigger: "change",
    },
  ],
  introduce: [
    {
      required: true,
      message: "请输入景点简介",
      trigger: "blur",
    },
  ],
});
/** 景点 富文本容器 */
const spotEditorElem = ref(null);
/** 景点 富文本实例对象 */
const spotEditor = ref(null);
/** 景点 富文本初始化 */
const spotEditorInit = () => {
  spotEditor.value = new Editor(spotEditorElem.value);
  spotEditor.value.config.colors = [
    "#000000",
    "#eeece0",
    "#1c487f",
    "#4d80bf",
    "#0072f6",
    "#1a1a1a",
    "#FFB900",
    "#EB5774",
    "#f2f2f2",
    "#666666",
    "#999999",
    "#cccccc",
  ];
  // 配置 onchange 回调函数
  spotEditor.value.config.onchange = (newHtml) => {
    spotForm.introduce = newHtml;
    spotFormObj.value.validateField("introduce");
  };
  // 自定义菜单配置
  spotEditor.value.config.menus = [
    "head",
    "bold",
    "fontSize",
    "fontName",
    "italic",
    "underline",
    "strikeThrough",
    "indent",
    "lineHeight",
    "foreColor",
    "justify",
    "quote",
    "splitLine",
    "image",
    "undo",
    "redo",
  ];
  // 限制上传图片格式
  spotEditor.value.config.uploadImgAccept = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
  ];
  spotEditor.value.config.qiniu = true;
  spotEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
  const upToken = qiniuData.value.token;
  const domain = qiniuData.value.http_domain;
  spotEditor.value.config.customUploadImg = (file) => {
    if (file.length) {
      file.forEach((el) => {
        const myDate = new Date();
        const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
        const timeData = Date.parse(new Date()); //当前时间时时间戳
        const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
        const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
        const filename = el.name;
        const index = filename.lastIndexOf(".");
        const suffix = filename.substr(index + 1);
        const key = `resource-spot-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
        const config = {
          // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          cdnUphost: domain,
        };
        const putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        const observable = Qiniu.upload(el, key, upToken, putExtra, config);
        observable.subscribe({
          error: () => {},
          complete: (res) => {
            const sourceLink = domain + res.key;
            spotEditor.value.cmd.do(
              "insertHtml",
              '<img src="' + sourceLink + '" style="max-width:100%;"/>'
            );
            spotForm.images.push({
              filename: filename,
              qiniu_key: res.key,
              size: el.size,
            });
          },
        });
      });
    }
  };

  spotEditor.value.config.height = 300;
  // 创建富文本实例
  spotEditor.value.create();
  spotEditor.value.txt.html(spotForm.introduce);
};
/** 项目选择更改 */
const projectChange = (value) => {
  spotForm.scenic = "";
  scenicOptions.value = [];
  if (value) {
    // 获取景区列表数据
    getScenicByProject({ pid: value });
  }
};
/** 景点新增/编辑 确认 */
const spotEditConfirm = () => {
  spotFormObj.value.validate((valid) => {
    if (valid) {
      scenicSpot.value.isLoading = true;
      let stime = dayjs(spotForm.transitTime[0]).format("HH:mm:ss");
      let etime = dayjs(spotForm.transitTime[1]).format("HH:mm:ss");
      let data = {
        sp_name: spotForm.name,
        sp_business_hours: stime + "-" + etime,
        sp_introduce: spotForm.introduce,
        images: spotForm.images,
      };
      let request = null;
      let text = "";
      if (isSpotEdit.value) {
        text = "编辑";
        data.sp_id = currentSpot.value.sp_id;
        request = ScenicApi.spotEntranceEdit(data);
      } else {
        text = "新增";
        data.sp_pid = spotForm.village;
        data.sp_sid = spotForm.scenic;
        request = BasicApi.spotAdd(data);
      }
      request.then((res) => {
        scenicSpot.value.isLoading = false;
        if (res.Code === 200) {
          ElMessage.success(`景点${text}成功！`);
          scenicSpot.value.close();
          if (isSpotEdit.value) {
            // 重新获取景区入口数据
            spotTable.value.tableLoad();
          } else {
            scenicTable.value.tableLoad();
          }
        } else {
          let msg = res.Message ? res.Message : `景点${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 景区入口 弹框 */
const areaEntrance = ref(null);
/** 景区入口表格对象 */
const areaTable = ref(null);
/** 景区入口 表格配置 */
const areaTableColumns = ref([
  {
    prop: "se_name",
    label: "入口名称",
    color: "--text-color",
  },
  {
    prop: "p_name",
    label: "所属项目",
    color: "--text-third-color",
  },
  {
    prop: "relation_name",
    label: "所属景区",
    minWidth: 140,
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    prop: "se_status",
    label: "入口状态",
    type: "switch",
    token: "r_cvtfzWQ142wmb7uCTJ6aK50il83D",
  },
  {
    prop: "se_access_stime",
    label: "通行开始时间",
    minWidth: 80,
    color: "--text-third-color",
  },
  {
    prop: "se_access_etime",
    label: "通行结束时间",
    minWidth: 80,
    color: "--text-third-color",
  },
]);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    se_id: row.se_id,
    se_status: row.se_status == 1 ? 2 : 1,
  };
  BasicApi.setEntranceStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      nextTick(() => {
        // 获取景区入口列表数据
        areaTable.value.tableLoad();
      });
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
};
/** 景点入口 弹框 */
const spotEntrance = ref(null);
/** 包含景点表格对象 */
const spotTable = ref(null);
/** 景点入口 表格配置 */
const spotTableColumns = ref([
  {
    prop: "sp_name",
    label: "景点名称",
    minWidth: 160,
    color: "--text-color",
    showTooltip: true,
  },
  {
    type: "switch",
    prop: "sp_status",
    label: "景点状态",
    minWidth: 70,
    token: "c_6nsjoSHFRdc59UYwXIJK2gvz0ZAE",
  },
  {
    prop: "u_name",
    label: "创建人",
    color: "--text-third-color",
  },
  {
    prop: "ctime",
    label: "创建时间",
    color: "--text-third-color",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "c_vHZrXyFn2bkdQmptGhDiO1AwY6UB",
      },
    ],
  },
]);
function spotStatusChange(row) {
  let data = {
    sp_id: row.sp_id,
    sp_status: row.sp_status == 1 ? 2 : 1,
  };
  BasicApi.setSpotStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      nextTick(() => {
        // 获取景区入口列表数据
        spotTable.value.tableLoad();
      });
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
/** 当前编辑景点 行数据 */
const currentSpot = ref(null);
/** 景点编辑 */
const spotEdit = (row) => {
  isSpotEdit.value = true;
  scenicSpot.value.show();
  ScenicApi.spotEntranceInfo({ sp_id: row.sp_id }).then((res) => {
    if (res.Code === 200) {
      currentSpot.value = res.Data;
      spotForm.name = res.Data.sp_name;
      spotForm.introduce = res.Data.sp_introduce;
      let times = res.Data.sp_business_hours.split("-");
      let st = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[0]);
      let et = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[1]);
      spotForm.transitTime = [st, et];
      spotForm.images = res.Data.images || [];
      spotEditorInit();
    } else {
      let msg = res.Message ? res.Message : "获取景点详情数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 景区 表格1部分 结束 */

/** 酒店 表格2部分 开始 */
/** 表格2 筛选条件列表 */
const filters2 = ref([
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
  },
]);
/** 表格2 配置数据 */
const tableColumns2 = ref([
  {
    prop: "h_id",
    label: "酒店ID",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "project",
    prop2: "p_name",
    label: "所属项目",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "h_name",
    label: "酒店名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "h_hotline",
    label: "联系电话",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    type: "customRender",
    prop: "points_rule",
    label: "积分规则",
    minWidth: 130,
  },
  {
    prop: "h_address",
    label: "地址信息",
    minWidth: 150,
    color: "--text-color",
    showTooltip: true,
  },
  {
    type: "switch",
    prop: "points_ded",
    label: "抵扣积分",
    token: "r_j8kMlM7MNlJWzKpv42UZYOlm6s4Y",
  },
  {
    prop: "h_introduce",
    label: "酒店简介",
    minWidth: 150,
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 190,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "r_MPqBIKiC5Nf6YxFndGmDw14LUk70",
        className: "theme-font-btn",
      },
      {
        name: "关联其他",
        action: "relatedOthers",
        token: "r_r03OMVyT1Bn6FXw4cbkEphYfIxvG",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 酒店表格对象 */
const hotelTable = ref(null);
/** 酒店表格当前操作行 */
const currentHotel = ref(null);
/** 表格2 编辑 */
const hotelEdit = (row) => {
  currentHotel.value = row;
  isHotelEdit.value = true;
  hotelForm.village = row.h_pid; // 项目
  hotelForm.name = row.h_name; // 酒店名称
  hotelForm.tel = row.h_hotline; // 联系电话
  hotelForm.addr = row.h_address; // 酒店地址
  hotelForm.intro = row.h_introduce; // 酒店简介
  hotelDialog.value.show();
};

/** 新增/编辑 酒店弹框 */
const hotelDialog = ref(null);
/** 酒店编辑/新增操作 */
const isHotelEdit = ref(false);
/** 酒店表单对象 */
const hotelFormObj = ref(null);
/** 酒店表单数据对象 */
const hotelForm = reactive({
  village: "", // 项目
  name: "", // 酒店名称
  tel: "", // 联系电话
  addr: "", // 酒店地址
  intro: "", // 酒店简介
});
/** 酒店表单规则对象 */
const hotelRules = reactive({
  village: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入酒店名称",
      trigger: "blur",
    },
  ],
  tel: [
    {
      required: false,
      message: "请输入酒店联系电话",
      trigger: "blur",
    },
  ],
  addr: [
    {
      required: false,
      message: "请输入酒店地址",
      trigger: "blur",
    },
  ],
  intro: [
    {
      required: false,
      message: "请输入酒店的详细描述",
      trigger: "blur",
    },
  ],
});
/** 弹出 新增/编辑酒店 弹框 */
const showHotelDialog = () => {
  isHotelEdit.value = false;
  hotelForm.village = ""; // 项目
  hotelForm.name = ""; // 酒店名称
  hotelForm.tel = ""; // 联系电话
  hotelForm.addr = ""; // 酒店地址
  hotelForm.intro = ""; // 酒店简介
  hotelDialog.value.show();
};
/** 新增/编辑酒店确认 */
const hotelConfirm = () => {
  hotelFormObj.value.validate((valid) => {
    if (valid) {
      hotelDialog.value.isLoading = true;
      let data = {
        h_name: hotelForm.name,
        h_pid: hotelForm.village,
        h_hotline: hotelForm.tel,
        h_address: hotelForm.addr,
        h_introduce: hotelForm.intro,
      };
      let url = "hotelAdd";
      if (isHotelEdit.value) {
        url = "hotelUpdate";
        data.h_id = currentHotel.value.h_id;
      }
      BasicApi[url](data).then((res) => {
        hotelDialog.value.isLoading = false;
        let text = isHotelEdit.value ? "编辑" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`酒店${text}成功！`);
          hotelDialog.value.close();
          // 重新获取酒店数据
          hotelTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `酒店${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};
/** 酒店 表格2部分 结束 */

/** 餐厅 表格3部分 开始 */

/** 表格3 筛选条件列表 */
const filters3 = ref([
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
  },
]);
/** 表格3 配置数据 */
const tableColumns3 = ref([
  {
    prop: "dr_id",
    label: "餐厅ID",
    color: "--text-color",
  },
  {
    prop: "project",
    label: "所属项目",
    type: "obj",
    objKey: "p_name",
    color: "--text-third-color",
  },
  {
    prop: "dr_name",
    label: "餐厅名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "dr_hotline",
    label: "联系电话",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    type: "customRender",
    prop: "points_rule",
    label: "积分规则",
    minWidth: 130,
  },
  {
    prop: "dr_address",
    label: "地址信息",
    minWidth: 150,
    color: "--text-color",
    showTooltip: true,
  },
  {
    type: "switch",
    prop: "points_ded",
    label: "抵扣积分",
    token: "r_j8kMlM7MNlJWzKpv42UZYOlm6s4Y",
  },
  {
    prop: "dr_introduce",
    label: "餐厅简介",
    minWidth: 150,
    showTooltip: true,
    color: "--text-color",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "r_YV0NXIBmtjz7JOWeGSrhZb48D9vM",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 餐厅表格对象 */
const diningTable = ref(null);
/** 餐厅当前操作行 */
const currentDining = ref(null);
/** 表格3 编辑 */
const diningEdit = (row) => {
  currentDining.value = row;
  isDiningEdit.value = true;
  let times = row.dr_open_time.split("-");
  let st = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[0]);
  let et = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[1]);
  diningForm.village = row.dr_pid; // 项目
  diningForm.name = row.dr_name; // 餐厅名称
  diningForm.tel = row.dr_hotline; // 联系电话
  diningForm.addr = row.dr_address; // 餐厅地址
  diningForm.intro = row.dr_introduce; // 餐厅简介
  diningForm.coordinate = row.dr_coordinate; // 餐厅坐标
  diningForm.tag = row.dr_tag[0]; // 餐厅标签
  diningForm.transitTime = [st, et]; // 营业时间
  diningDialog.value.show();
};

/** 新增/编辑 酒店弹框 */
const diningDialog = ref(null);
/** 酒店编辑/新增操作 */
const isDiningEdit = ref(false);
/** 酒店表单对象 */
const diningFormObj = ref(null);
/** 酒店表单数据对象 */
const diningForm = reactive({
  village: "", // 项目
  name: "", // 餐厅名称
  tel: "", // 联系电话
  addr: "", // 餐厅地址
  intro: "", // 餐厅简介
  coordinate: "", // 餐厅坐标
  tag: "", // 餐厅标签
  transitTime: "", // 营业时间
});
/** 酒店表单规则对象 */
const diningRules = reactive({
  village: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入餐厅名称",
      trigger: "blur",
    },
  ],
  tel: [
    {
      required: true,
      message: "请输入餐厅联系电话",
      trigger: "blur",
    },
  ],
  addr: [
    {
      required: true,
      message: "请输入餐厅地址",
      trigger: "blur",
    },
  ],
  intro: [
    {
      required: true,
      message: "请输入餐厅的详细描述",
      trigger: "blur",
    },
  ],
  coordinate: [
    {
      required: true,
      message: "请输入餐厅坐标",
      trigger: "blur",
    },
  ],
  tag: [
    {
      required: true,
      message: "请输入餐厅标签",
      trigger: "blur",
    },
  ],
  transitTime: [
    {
      required: true,
      message: "请选择营业时间",
      trigger: "change",
    },
  ],
});
/** 弹出 新增/编辑餐厅 弹框 */
const showDiningDialog = () => {
  isDiningEdit.value = false;
  diningForm.village = ""; // 项目
  diningForm.name = ""; // 餐厅名称
  diningForm.tel = ""; // 联系电话
  diningForm.addr = ""; // 餐厅地址
  diningForm.intro = ""; // 餐厅简介
  diningForm.coordinate = ""; // 餐厅坐标
  diningForm.tag = ""; // 餐厅标签
  diningForm.transitTime = ""; // 营业时间
  diningDialog.value.show();
};
/** 餐厅 新增/编辑 确认 */
const dinnerConfirm = () => {
  diningFormObj.value.validate((valid) => {
    if (valid) {
      diningDialog.value.isLoading = true;
      let stime = dayjs(diningForm.transitTime[0]).format("HH:mm:ss");
      let etime = dayjs(diningForm.transitTime[1]).format("HH:mm:ss");
      let data = {
        dr_pid: diningForm.village,
        dr_name: diningForm.name,
        dr_hotline: diningForm.tel,
        dr_address: diningForm.addr,
        dr_coordinate: diningForm.coordinate,
        dr_tag: diningForm.tag,
        dr_open_time: stime + "-" + etime,
        dr_introduce: diningForm.intro,
      };
      let url = "dinnerAdd";
      if (isDiningEdit.value) {
        url = "dinnerUpdate";
        data.dr_id = currentDining.value.dr_id;
      }
      BasicApi[url](data).then((res) => {
        diningDialog.value.isLoading = false;
        let text = isDiningEdit.value ? "编辑" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`餐厅${text}成功！`);
          diningDialog.value.close();
          // 重新获取餐厅数据
          diningTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `餐厅${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};
/** 餐厅 表格3部分 结束 */

/** 监听标签是否改变 */

/** 项目列表数据 */
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      projectOptions.value = res.Data ? res.Data : [];
      filters1.value[0].options = res.Data ? res.Data : [];
      filters2.value[0].options = res.Data ? res.Data : [];
      filters3.value[0].options = res.Data ? res.Data : [];
      filters4.value[0].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 景区列表数据 */
const scenicOptions = ref([]);
/** 获取项目下景区数据 */
const getScenicByProject = (data) => {
  BasicApi.getScenicByProject(data).then((res) => {
    if (res.Code === 200) {
      scenicOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取景区数据失败！";
      ElMessage.error(msg);
    }
  });
};

const integralRuleOption = ref([]);  // 积分规则选项
/**
 * 
 * 获取积分规则选项
 * 
 * **/
const getPointsRules = () => {
  BasicApi.getPointsRules().then((res) => {
    if (res.Code === 200) {
      integralRuleOption.value = res.Data ? res.Data : [];
    } else {
      ElMessage.error(res.Message);
    }
  });
}
/**
 * 
 * 选择积分规则
 * 
 * **/
const integralRuleChange = (row, type) => {
  let params = {
    key: 'points_rule',
    value: row.points_rule,
  };
  if(type == '1') {
    // 景区
    params.s_id = row.s_id;
  } else if(type == '2') {
    // 酒店
    params.h_id = row.h_id;
  } else if(type == '3') {
    // 餐厅
    params.dr_id = row.dr_id;
  } else if(type == '4') {
    // 门店
    params.pom_id = row.pom_id;
  }
  resourceConfig(params);
}
/**
 * 
 * 景区抵扣积分
 * 
 * **/
const scenicSpotStatus = (row) => {
  let params = {
    s_id: row.s_id,
    key: 'points_ded',
    value: row.points_ded == 1 ? 2 : 1,
  };
  resourceConfig(params);
}
/**
 * 
 * 酒店抵扣积分
 * 
 * **/
const hotelStatus = (row) => {
  let params = {
    h_id: row.h_id,
    key: 'points_ded',
    value: row.points_ded == 1 ? 2 : 1,
  };
  resourceConfig(params);
}
/**
 * 
 * 餐厅抵扣积分
 * 
 * **/
const dinnerStatus = (row) => {
  let params = {
    dr_id: row.dr_id,
    key: 'points_ded',
    value: row.points_ded == 1 ? 2 : 1,
  };
  resourceConfig(params);
}
/**
 * 
 * 门店抵扣积分
 * 
 * **/
const storeStatus = (row) => {
  let params = {
    pom_id: row.pom_id,
    key: 'points_ded',
    value: row.points_ded == 1 ? 2 : 1,
  };
  resourceConfig(params);
}
/**
 * 
 * 修改积分规则
 * key  points_rule：积分规则  points_ded：积分抵扣
 * 
 * **/
const resourceConfig = (params) => {
  BasicApi.resourceConfig(params).then((res) => {
    if (res.Code === 200) {
      ElMessage.success('操作成功！');
      if(activeName.value == "scenic") {
        // 景区
        scenicTable.value.tableLoad();
      } else if(activeName.value == "hotel") {
        // 酒店
        hotelTable.value.tableLoad();
      } else if(activeName.value == "dining") {
        // 餐厅
        diningTable.value.tableLoad();
      } else if(activeName.value == "store") {
        // 门店
        storeTable.value.tableLoad();
      }
    } else {
      ElMessage.error(res.Message);
    }
  });
}
const correlatRef = ref(null);  // 关联其他对象
/**
 * 
 * 酒店-关联其他
 * 
 * **/
const relatedOthers = (row) => {
  correlatRef.value.openDialog(row, 3);
}
/**
 * 
 * 关联成功获取列表数据
 * 
 * **/
const onSubmit = () => {
  if(activeName.value == "scenic") {
    // 景区
    scenicTable.value.tableLoad();
  } else if(activeName.value == "hotel") {
    // 酒店
    hotelTable.value.tableLoad();
  } else if(activeName.value == "dining") {
    // 餐厅
    diningTable.value.tableLoad();
  } else if(activeName.value == "store") {
    // 门店
    storeTable.value.tableLoad();
  }
}
/** 门店列表 **/
const filters4 = ref([
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
  },
]);
  // 表格配置数据
  const tableColumns4 = ref([
    {
      prop: "pom_id",
      label: "门店ID",
    },
    {
      prop: "p_name",
      label: "所属项目",
      color: "--text-third-color",
    },
    {
      prop: "pom_name",
      label: "门店名称",
      color: "--text-third-color",
    },
    {
      prop: "goods_count",
      label: "在售商品(件)",
    },
    {
      type: "customRender",
      prop: "points_rule",
      label: "积分规则",
      minWidth: 130,
    },
    {
      prop: "pom_status",
      type: "customRender",
      label: "门店状态",
    },
    {
      type: "customRender",
      prop: "refund_status",
      label: "退款审核",
    },
    {
      type: "switch",
      prop: "points_ded",
      label: "抵扣积分",
      token: "r_j8kMlM7MNlJWzKpv42UZYOlm6s4Y",
    },
    {
      prop: "pom_remark",
      label: "门店备注",
      minWidth: 200,
      color: "--text-third-color",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "编辑",
          action: "onAddEditor",
          token: "r_tGWF8UvC6LV6a0KadryjhQQoY7Bh",
        },
      ],
    },
  ]);
  const storeTable = ref(null);  // 门店表格对象
  const dialogRef = ref(null);
  // 表单对象
  const formRef = ref(null);
  // 新增、编辑填写内容
  const ruleForm = ref({
    pom_pid: "",  // 项目
    pom_type: "",  // 分类
    pom_name: "",  // 名称
    pom_remark: "",  // 备注
  });
  // 验证内容不为空
  const storeRules = reactive({
    pom_pid: [{ required: true, message: "请选择项目", trigger: "chaneg" }],
    pom_type: [{ required: true, message: "请选择门店所属分类", trigger: "chaneg" }],
    pom_name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
  });
  // 分类下拉选项
  const classification = ref([{ pom_type: "1", t_Name: "线上" }, { pom_type: "2", t_Name: "线下" }]);
  /**
   * 
   * 新增、编辑门店
   * 
   * */
  const onAddEditor = (row) => {
    if (row.pom_id) {
      //编辑
      const data = JSON.parse(JSON.stringify(row));
      data.pom_type = String(data.pom_type);
      ruleForm.value = data;
    } else {
      // 新增
      ruleForm.value = {
        pom_pid: "",  // 项目
        pom_type: "",  // 分类
        pom_name: "",  // 名称
        pom_remark: "",  // 备注
      };
    }
    dialogRef.value.show();
  };
  /**
   * 
   * 新增/编辑 确认提交按钮
   * 
   * */
  const handleSure = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        dialogRef.value.isLoading = true;
        const data = JSON.parse(JSON.stringify(ruleForm.value));
        let url = "";
        if (data.pom_id) {
          // 编辑
          url = "updatePolymerization";
        } else {
          // 新增
          url = "addStorePolymerization";
        }
        BasicApi[url](data).then((res) => {
          dialogRef.value.isLoading = false;
          const text = data.pom_id ? "修改" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`门店${text}成功！`);
            dialogRef.value.close();
            storeTable.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : `门店${text}失败！`;
            ElMessage.error(msg);
          }
        });
      }
    });
  };

onMounted(() => {
  // 获取项目数据
  getProjectData();
  getPointsRules();
});
</script>

<style lang="scss">
.resmanage {
  font-family: "Source Han Sans CN";
  > .el-tabs {
    .el-tabs__nav {
      padding: 0 20px;
      .el-tabs__item {
        height: 50px;
        line-height: 50px;
        color: var(--text-second-color);
        &.is-active {
          font-weight: 700;
          font-size: 16px;
          color: var(--theme-color);
        }
      }
    }
    .el-tabs__content {
      padding: 2px 20px 20px;
    }
  }
  .scenic-area,
  .scenic-spot {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 40px;
        .area-form,
        .spot-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-date-editor {
                width: 100%;
                .el-range-input {
                  background-color: transparent;
                }
              }
            }
          }
          .el-form-item.block {
            width: 100%;
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  .entrance {
    .el-dialog {
      min-width: 640px;
      .el-dialog__body {
        .name {
          font-size: 16px;
          font-weight: 700;
          color: var(--text-color);
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
